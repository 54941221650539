html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 1em;
  line-height: 1.5;
  background-color: #fff;
  color: #2f2f2f;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #3e73d4;
}
